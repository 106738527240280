<template>
  <div class="wrapper">
    <div class="row">
      <h3>Добавление филиала</h3>
    </div>

    <div class="row">
      <div class="col-md-10 col-lg-6">
        <form @submit.prevent novalidate>
          <InputGroup :labelTxt="'Полное наименование филиала*'"
                      :inputId="'1'"
                      :errorTxt="filialsErrorTextByName('filialName') || filialNameErr"
                      v-model="filialName" :val="filialName" />

          <InputGroup :labelTxt="'Адрес филиала*'"
                      :inputId="'2'"
                      :errorTxt="filialsErrorTextByName('filialAddress') || filialAddressErr"
                      v-model="filialAddress" :val="filialAddress"  />

          <InputGroup :labelTxt="'КПП филиала*'"
                      :inputId="'3'" :type="'number'"
                      :minLength="kppLength"
                      :errorTxt="filialsErrorTextByName('filialKpp') || filialKppErr"
                      v-model="filialKpp" :val="filialKpp"  />



          <div class="row align-center space-between">
            <a class="btn btn-bg" @click="sendForm" :class="{ 'disabled': sending1}">Далее</a>
            <router-link to="/filials/">Отменить</router-link>
          </div>


        </form>
      </div>
    </div>
  </div>

</template>

<script>
import InputGroup from "@/components/form/InputGroup";
import {mapGetters} from "vuex";
export default {
name: "AddFilials",
  components: {InputGroup},
  data() {
    return {
      id: "",
      filialId: "",
      filialName: "",
      filialNameErr: "",
      filialAddress: "",
      filialAddressErr: "",
      filialKpp: "",
      kppLength: 9,
      filialKppErr: "",
      isSent: false,
      sending1: false
    }
  },
  mounted() {
    if(this.$route.params.id !== undefined) {
      var filial = this.$store.getters.filialById(this.$route.params.id);

      if(filial != undefined) {
        this.filialName = filial.filialName;
        this.filialNameErr = "";
        this.filialAddress = filial.filialAddress;
        this.filialAddressErr = "";
        this.filialKpp = filial.filialKpp;
        this.filialKppErr = "";

        this.filialId = filial.filialId;
      }
      else {
        this.$store.dispatch('getFilials');
      }

      this.id = this.$route.params.id;
    }
  },

  computed: {
    ...mapGetters([
      'filialsErrorTextByName'
    ]),
  },
  methods: {
    async sendForm() {
      if(this.sending1)
        return;

      let init = this;
      this.sending1 = true;

      let errtxt = "Поле обязательно для заполнения";
      let check_req = false;

      this.filialNameErr = "";
      this.filialAddressErr = "";
      this.filialKppErr = "";

      if(this.filialName.trim() === "")
      {
        this.filialNameErr = errtxt;
        check_req = true;
      }
      if(this.filialAddress.trim() === "")
      {
        this.filialAddressErr = errtxt;
        check_req = true;
      }
      if(this.filialKpp.trim() === "")
      {
        this.filialKppErr = errtxt;
        check_req = true;
      }

      if(this.filialKpp.trim().length < this.kppLength)
      {
        this.filialKppErr = "Минимальное количество символов: " + this.kppLength;
        check_req = true;
      }

      if(check_req) {
        this.sending1 = false;
        return;
      }


      if(init.id != "") {
        await this.$store.dispatch('updateFilial', {
          "filialId": init.id,
          "filialName": init.filialName,
          "filialAddress": init.filialAddress,
          "filialKpp": init.filialKpp
        }).then((resp) => {
          this.sending1 = false;
        });
      }
      else {
        await this.$store.dispatch('addFilial', {
          "filialName": init.filialName,
          "filialAddress": init.filialAddress,
          "filialKpp": init.filialKpp
        }).then((resp) => {
          this.sending1 = false;
        });
      }


    }
  }
}
</script>

<style scoped lang="scss">

h3 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 32px;
}


form {

  font-size: 18px;
  line-height: 1.56;

  a {
    cursor: pointer;
  }

  p.err {
    color: #e5221c;
    margin-top: 10px;
    font-size: 16px;
    line-height: 26px;
  }

  .btn {
    width: 100%;
    max-width: 350px;
  }



  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    label {
      color: $navy;
      margin-bottom: 11px;
    }


    p {
      color: #e5221c;
      margin-top: 10px;
      font-size: 16px;
      line-height: 26px;
    }
  }

  input{
    font-size: 18px;
    color: $text-gray;
    padding: 18px 23px;
    border: 1px solid $light-border;
    border-radius: 8px;
  }

  .icon {
    display: none;
  }

  input:focus, select:focus {
    outline: none;
    color: #000;
  }


}


@include md-max {

  .btn-bg {
    margin-bottom: 10px;
  }


  h3 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  form {
    font-size: 16px;
    .input-group {
      margin-bottom: 10px;
    }
  }

}

</style>
